import { invokeApig } from './awsLib';

type RequestFn = (props: {
  path: string;
  method?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE' | 'HEAD';
  headers?: { [key: string]: any };
  queryParams?: { [key: string]: any };
  body?: { [key: string]: any };
  formData?: FormData;
  publicAPI?: boolean;
  signal?: AbortSignal;
  onError?: (error: Error) => Promise<void>;
}) => Promise<any>;

type RequestModule = RequestFn & {
  post: RequestFn;
  get: RequestFn;
  put: RequestFn;
  delete: RequestFn;
};

export default class ApiBridge {
  private apiInstance: RequestModule;

  constructor(apiInstance = invokeApig as RequestModule) {
    this.apiInstance = apiInstance;
  }

  async generatePreSignedUploadURL({
    filesize,
    contentType,
    key,
    file,
  }: {
    filesize: number;
    contentType: string;
    key: string;
    file: any;
  }) {
    const formData = new FormData();

    formData.append('key', key);
    formData.append('contentType', contentType);
    formData.append('filesize', filesize.toString());
    formData.append('file', (await file.text()).slice(0, 2e6));

    return this.apiInstance.post({
      path: '/apis/s3',
      formData,
      publicAPI: true,
      onError: async (res: any) => {
        if (res.status === 403) {
          throw new Error('WAF');
        }

        throw await res.json();
      },
    });
  }

  composePrivateFileLink({ path }: { path: string }): Promise<string> {
    return this.apiInstance.get({
      path: '/apis/auth/s3',
      method: 'GET',
      queryParams: {
        path: btoa(path),
      },
    });
  }

  getUser(userId: string) {
    return this.apiInstance.get({ path: `/admin/users/${userId}` });
  }

  updateUser(userId: string, data: any) {
    return this.apiInstance.put({
      path: `/admin/users/${userId}`,
      body: data,
    });
  }

  createUser(data: any) {
    return this.apiInstance.post({
      path: '/admin/users',
      body: data,
    });
  }

  getCustomerOrganisations(params?: { _source: string[] }) {
    return this.apiInstance.post({
      path: '/apis/v2/auth/es',
      body: {
        query: {
          bool: {
            filter: {
              term: {
                isCustomerOrganisation: true,
              },
            },
          },
        },
        sort: [
          {
            'customerOrganisationCustomName.keyword': 'asc',
            'name.keyword': 'asc',
          },
        ],
        index: 'users',
        ...(!!params?._source?.length && { _source: params._source }),
        size: 10e3,
      },
    });
  }

  getSmsNotificationsTemplateLink() {
    return this.apiInstance.get({
      path: '/admin/misc/sms-notifications',
    });
  }
}

import React, { Suspense, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import { throttleCheckUser } from './services/awsLib';

const DefaultHeader = React.lazy(() =>
  import('./components/defaultLayout/DefaultHeader'),
);
const DefaultNav = React.lazy(() =>
  import('./components/defaultLayout/DefaultNav'),
);
const Login = React.lazy(() => import('./components/auth/login/login'));
const ForgetPassword = React.lazy(() =>
  import('./components/auth/ForgetPassword/ForgetPassword'),
);
const Dashboard = React.lazy(() => import('./components/dashboard/dashboard'));
const Users = React.lazy(() =>
  import('./components/users/listUsers/listUsers'),
);
const UsersImport = React.lazy(() =>
  import('./components/users/import/UsersImport'),
);
const EditUser = React.lazy(() => import('./components/users/edit/EditUser'));
const Services = React.lazy(() =>
  import('./components/services/listServices/listServices'),
);
const AddEditServices = React.lazy(() =>
  import('./components/services/addEditService/addEditService'),
);
const Menu = React.lazy(() =>
  import('./components/menu/listMenuItems/listMenuItems'),
);
const Skills = React.lazy(() =>
  import('./components/skills/listServices/listServices'),
);
const AddEditSkills = React.lazy(() =>
  import('./components/skills/addEditService/addEditService'),
);
const ListMessages = React.lazy(() =>
  import('./components/messages/listMessage/listMessages'),
);
const ListChannel = React.lazy(() =>
  import('./components/messages/listChannel/listChannel'),
);
const ListJobs = React.lazy(() =>
  import('./components/jobs/listJobs/listJobs'),
);
const ListProposals = React.lazy(() =>
  import('./components/jobs/listProposals/listProposals'),
);
const ListMilestones = React.lazy(() =>
  import('./components/jobs/listMilestones/listMilestone'),
);
const JobsImport = React.lazy(() =>
  import('./components/jobs/import/JobsImport'),
);
const ListInvoices = React.lazy(() =>
  import('./components/invoices/listInvoices/listInvoices'),
);
const ListTransactions = React.lazy(() =>
  import('./components/transactions/listTransactions/listTransactions'),
);
const Subscriptions = React.lazy(() => import('./components/subscriptions'));
const ListReviews = React.lazy(() =>
  import('./components/reviews/listReviews/listReviews'),
);
const ListReferences = React.lazy(() =>
  import('./components/references/ReferenceTable'),
);
const DataTypes = React.lazy(() => import('./components/dataTypes/list'));
const AddEditDataTypes = React.lazy(() =>
  import('./components/dataTypes/addEdit'),
);
const ImportDataTypes = React.lazy(() =>
  import('./components/dataTypes/import/ImportDataTypes'),
);
const Content = React.lazy(() => import('./components/content/content'));
const Styling = React.lazy(() => import('./components/styling'));
const Payment = React.lazy(() => import('./components/payment'));
const Settings = React.lazy(() => import('./components/settings'));
const SEO = React.lazy(() => import('./components/seo'));
const Webhooks = React.lazy(() => import('./components/webhooks/Webhooks'));
const SingleWebhook = React.lazy(() =>
  import('./components/webhooks/SingleWebhook'),
);
const CreateWebhook = React.lazy(() =>
  import('./components/webhooks/CreateWebhook'),
);
const Notifications = React.lazy(() =>
  import('./components/notifications/Notifications'),
);

export const PublicRoutes = () => (
  <div className='app'>
    <div className='app-body'>
      <main className='main'>
        <Container fluid>
          <Suspense>
            <Switch>
              <Route
                exact
                path='/'
                name='Login Page'
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path='/forget-password'
                name='Forgot Password Page'
                render={(props) => <ForgetPassword {...props} />}
              />

              <Route render={() => <Redirect to='/' />} />
            </Switch>
          </Suspense>
        </Container>
      </main>
    </div>
  </div>
);

export const PrivateRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/') {
      throttleCheckUser();
    }
  }, [location]);

  return (
    <div className='app'>
      <DefaultHeader />
      <div className='app-body'>
        <DefaultNav />
        <main className='main'>
          <Container fluid>
            <Suspense>
              <Switch>
                <Route
                  exact
                  path='/dashboard'
                  name='Dashboard'
                  render={(props) => (
                    <Dashboard {...props} section={'dashboardHTML'} />
                  )}
                />
                <Route
                  exact
                  path='/users'
                  name='Users'
                  render={(props) => <Users {...props} />}
                />
                <Route
                  exact
                  path='/users/import'
                  name='Users import'
                  render={(props) => <UsersImport {...props} />}
                />
                <Route
                  exact
                  path='/users/dashboard'
                  name='Users dashboard'
                  render={(props) => (
                    <Dashboard {...props} section={'usersDashboardHTML'} />
                  )}
                />
                <Route
                  exact
                  path='/users/create'
                  name='Create user'
                  render={(props) => <EditUser mode={'create'} {...props} />}
                />
                <Route
                  exact
                  path='/users/:userId'
                  name='Edit user'
                  render={(props) => <EditUser mode={'edit'} {...props} />}
                />
                <Route
                  exact
                  path='/services'
                  name='Categories'
                  render={(props) => <Services {...props} />}
                />
                <Route
                  exact
                  path='/menu'
                  name='Menu'
                  render={(props) => <Menu {...props} />}
                />
                <Route
                  exact
                  path='/services/add'
                  name='Add Service'
                  render={(props) => <AddEditServices {...props} />}
                />
                <Route
                  exact
                  path='/services/edit/:serviceId'
                  name='Edit Service'
                  render={(props) => <AddEditServices {...props} />}
                />
                <Route
                  exact
                  path='/skills'
                  name='Skills'
                  render={(props) => <Skills {...props} />}
                />
                <Route
                  exact
                  path='/skills/add'
                  name='Add Skill'
                  render={(props) => <AddEditSkills {...props} />}
                />
                <Route
                  exact
                  path='/skills/edit/:serviceId'
                  name='Edit Skill'
                  render={(props) => <AddEditSkills {...props} />}
                />
                <Route
                  exact
                  path='/channels'
                  name='User Messages'
                  render={(props) => <ListChannel {...props} />}
                />
                <Route
                  exact
                  path='/channels/:jobId/:channelId'
                  name='User Messages'
                  render={(props) => <ListMessages {...props} />}
                />
                <Route
                  exact
                  path='/jobs/list-jobs'
                  name='Jobs'
                  render={(props) => <ListJobs {...props} />}
                />
                <Route
                  exact
                  path='/jobs/list-proposals'
                  name='Jobs'
                  render={(props) => <ListProposals {...props} />}
                />
                <Route
                  exact
                  path='/jobs/list-milestones'
                  name='Jobs'
                  render={(props) => <ListMilestones {...props} />}
                />
                <Route
                  exact
                  path='/jobs/import'
                  name='Bulk import'
                  render={(props) => <JobsImport {...props} />}
                />
                <Route
                  exact
                  path='/jobs/dashboard'
                  name='Jobs dashboard'
                  render={(props) => (
                    <Dashboard {...props} section={'jobsDashboardHTML'} />
                  )}
                />
                <Route
                  exact
                  path='/invoices'
                  name='Invoices'
                  render={(props) => <ListInvoices {...props} />}
                />
                <Route
                  exact
                  path='/transactions'
                  name='Transactions'
                  render={(props) => <ListTransactions {...props} />}
                />
                <Route
                  exact
                  path='/transactions/dashboard'
                  name='Transactions dashboard'
                  render={(props) => (
                    <Dashboard
                      {...props}
                      section={'transactionsDashboardHTML'}
                    />
                  )}
                />
                <Route
                  exact
                  path='/subscriptions'
                  name='Subscriptions'
                  render={(props) => <Subscriptions {...props} />}
                />
                <Route
                  exact
                  path='/reviews'
                  name='Reviews'
                  render={(props) => <ListReviews {...props} />}
                />
                <Route
                  exact
                  path='/references'
                  name='References'
                  render={(props) => <ListReferences {...props} />}
                />
                <Route
                  exact
                  path='/content'
                  name='Content'
                  render={(props) => (
                    <Content name={'Base translation'} {...props} />
                  )}
                />
                <Route
                  exact
                  path='/custom-data-translation'
                  name='Custom data translation'
                  render={(props) => (
                    <Content
                      filename={'custom-data-i18n'}
                      name={'Custom data translation'}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path='/styling'
                  name='Styling'
                  component={Styling}
                />
                <Route
                  exact
                  path='/payment'
                  name='Payment Gateway'
                  component={Payment}
                />
                <Route
                  exact
                  path='/settings'
                  name='Settings'
                  component={Settings}
                />
                <Route exact path='/seo' name='SEO' component={SEO} />
                <Route
                  exact
                  path='/data-types'
                  name='Data Types'
                  render={(props) => <DataTypes {...props} />}
                />
                <Route
                  exact
                  path='/data-types/add'
                  name='Add Data Type'
                  render={(props) => <AddEditDataTypes {...props} />}
                />
                <Route
                  exact
                  path='/data-types/edit/:dataTypeId'
                  name='Edit Data Type'
                  render={(props) => <AddEditDataTypes {...props} />}
                />
                <Route
                  exact
                  path='/data-types/edit/:dataTypeId/import'
                  name='Import Data Type items'
                  render={(props) => <ImportDataTypes {...props} />}
                />
                <Route
                  exact
                  path='/webhooks'
                  name='Webhooks'
                  render={(props) => <Webhooks {...props} />}
                />
                <Route
                  exact
                  path='/webhooks/create'
                  name='Create Webhook'
                  render={(props) => <CreateWebhook {...props} />}
                />
                <Route
                  exact
                  path='/webhooks/:id'
                  name='Single Webhook'
                  render={(props) => <SingleWebhook {...props} />}
                />
                <Route
                  exact
                  path='/notifications'
                  name='Notifications'
                  render={(props) => <Notifications {...props} />}
                />
                <Route render={() => <Redirect to='/dashboard' />} />
              </Switch>
            </Suspense>
          </Container>
        </main>
      </div>
    </div>
  );
};

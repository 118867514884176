import React from 'react';
import { Spinner } from 'reactstrap';

export function Loader(props) {
  return <Spinner animation='border' variant='dark' style={props.style} />;
}

export function AppLoader() {
  return (
    <Spinner
      style={{ width: '3rem', height: '3rem' }}
      className='loader-pos-center'
      animation='border'
      variant='dark'
    />
  );
}

import AWS from 'aws-sdk';
import isString from 'lodash/isString';
import throttle from 'lodash/throttle';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

import sigV4Client from './sigV4Client';
import { isLocalEnv } from '../utils';
import { getUserAttribute } from '../components/auth/store/auth.action';
import ApiBridge from './ApiBridge';

const TENANT_ID_HEADER = 'X-Tenant-Id'.toLowerCase();

export const invokeApig = async ({
  path,
  method,
  headers = {},
  queryParams = {},
  body,
  formData,
  raw,
  publicAPI,
}) => {
  headers = {
    ...headers,
    [TENANT_ID_HEADER]: process.env.REACT_APP_TENANT_ID,
  };

  throttleCheckUser();

  let credentials = AWS.config?.credentials;

  if (!credentials?.accessKeyId) {
    const currentUser = getCurrentUser();

    if (currentUser === null) {
      throw new Error('User is not logged in');
    }

    // Update new credentials
    const userToken = await getUserToken(currentUser);

    credentials = await getAwsCredentials(userToken);
  }

  let url = `${process.env.REACT_APP_API_GATEWAY_URL}${path}`;

  if (!publicAPI) {
    const newClient = sigV4Client.newClient({
      accessKey: credentials.accessKeyId,
      secretKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
      region: AWS.config.region,
      endpoint: process.env.REACT_APP_API_GATEWAY_URL,
    });
    const signedRequest = newClient.signRequest({
      method,
      path,
      headers,
      queryParams,
      body,
    });

    headers = signedRequest.headers;
    url = signedRequest.url;
  }

  body = formData || (body ? JSON.stringify(body) : body);

  if (isLocalEnv()) {
    headers = {
      ...headers,
      'cognito-authentication-provider': await new Promise((resolve, reject) =>
        getCurrentUser().getSession((err, session) => {
          if (err) {
            return reject(err);
          }

          const {
            idToken: {
              payload: { sub },
            },
          } = session;

          return resolve(`0:0:${sub}`);
        }),
      ),
    };
  }

  const result = await fetch(url, {
    method,
    headers,
    body,
  });

  if (result.status >= 400) {
    throw new Error(await result.text());
  }

  return raw ? result.text() : result.json();
};

// makes calls like invokeApig.post possible
['post', 'put', 'get', 'delete', 'head'].forEach((method) => {
  invokeApig[method] = (params) => {
    const httpMethod = method.toUpperCase();

    if (isString(params)) {
      return invokeApig({
        path: params,
        method: httpMethod,
      });
    }

    return invokeApig({
      ...params,
      method: httpMethod,
    });
  };
});

export async function authUser() {
  const currentUser = getCurrentUser();

  if (currentUser === null) {
    return { userObj: null, status: false };
  }

  const userToken = await getUserToken(currentUser);
  await getAwsCredentials(userToken);

  return { userObj: currentUser, status: true };
}

async function checkUser() {
  try {
    const user = await authUser();
    if (!user?.userObj) return;
    await getUserAttribute(user.userObj);
  } catch (error) {
    await signOutUser();
    window.location = '/';
  }
}

export const throttleCheckUser = throttle(checkUser, 10000);

async function getAwsCredentials(userToken) {
  const authenticator = `cognito-idp.${process.env.REACT_APP_S3_REGION}.amazonaws.com/${process.env.REACT_APP_COGNITO_USER_POOL_ID}`;

  AWS.config.update({ region: process.env.REACT_APP_S3_REGION });

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    Logins: {
      [authenticator]: userToken,
    },
  });

  await AWS.config.credentials.getPromise();

  return new Promise((resolve, reject) =>
    AWS.config.getCredentials((err, credentials) => {
      if (err) {
        return reject(err);
      }

      return resolve(credentials);
    }),
  );
}

function getUserToken(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getSession(function (err, session) {
      if (err) {
        reject(err);
        return;
      }
      resolve(session.getIdToken().getJwtToken());
    });
  });
}

export function getCurrentUser() {
  const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  });
  return userPool.getCurrentUser();
}

export function sendResetPasswordRequest(email) {
  const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  });
  const user = new CognitoUser({ Username: email, Pool: userPool });

  return new Promise((resolve, reject) =>
    user.forgotPassword({
      onSuccess: () => {
        resolve();
      },
      onFailure: (err) => {
        reject(err);
      },
    }),
  );
}

export function signOutUser() {
  const currentUser = getCurrentUser();

  if (currentUser !== null) {
    currentUser.signOut();

    // Remove user out of localStorage
    localStorage.clear();
  }

  if (AWS.config.credentials) {
    AWS.config.credentials.clearCachedId();
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({});
  }
}

export async function s3Upload(file, folder, fileName, options) {
  if (!(await authUser())) {
    throw new Error('User is not logged in');
  }

  const filename =
    fileName || process.env.REACT_APP_S3_BASE_TRANSLATION.split('/').pop();

  const key = `${folder}/${filename}`;

  const formData = new FormData();
  const { fields, url } = await new ApiBridge().generatePreSignedUploadURL({
    file,
    key,
    filesize: file.size,
    contentType: file.type,
  });

  Object.keys(fields).forEach((field) => formData.append(field, fields[field]));
  formData.append('file', file);

  const response = await fetch(url, { method: 'POST', body: formData });

  // Creates CLoudFront distribution invalidation so that language file is properly updated within CDN
  if (!fileName || options?.clearCache) {
    await invokeApig.put({ path: '/admin/settings/clear-cache' }).catch((e) => {
      console.error('Error during invalidation of data file distribution');
      console.error(e);
    });
  }

  return response;
}

export async function s3DeleteFile(key) {
  if (!(await authUser())) {
    throw new Error('User is not logged in');
  }

  return invokeApig.delete({
    path: '/apis/auth/s3',
    body: { key },
  });
}

export async function getS3Object(key) {
  if (!(await authUser())) {
    throw new Error('User is not logged in');
  }

  const s3 = new AWS.S3({
    params: {
      Bucket: process.env.REACT_APP_S3_BUCKET,
    },
    region: process.env.REACT_APP_S3_REGION,
  });

  const params = {
    Bucket: process.env.REACT_APP_S3_BUCKET,
    Key: key,
  };

  s3.getObject(params, function (err, data) {
    const fileData = JSON.stringify(data.Body.toString());
    const blob = new Blob([fileData], { type: 'text/json;charset=utf-8,' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'data.json';
    link.href = url;
    link.click();
  });
}

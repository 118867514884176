export const firstToUpper = (input: string) =>
  input.charAt(0).toUpperCase() + input.slice(1);

export const firstToLower = (input: string) =>
  input.charAt(0).toLowerCase() + input.slice(1);

export const cutString = (input: string, max = 120) => {
  if (input?.length > max) {
    return input.substring(0, max) + '…';
  }

  return input;
};

export const arrayMoveMutate = (array: any[], from: number, to: number) => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

export const arrayMove = (array: any[], from: number, to: number) => {
  array = [...array];

  arrayMoveMutate(array, from, to);

  return array;
};

export const isLocalEnv = (() => {
  const LOCAL_DOMAINS = ['localhost', '127.0.0.1', ''];

  return () => LOCAL_DOMAINS.includes(window.location.hostname);
})();

export function passEventValue<T = any>(fn: (value: T) => any) {
  return ({ target: { value } }: { target: { value: T } }) => fn(value);
}

export function onEnter(fn: () => any) {
  return ({ key }: { key: string }) => {
    if (key === 'Enter') {
      fn();
    }
  };
}

import { combineReducers } from 'redux';
import auth from '../../components/auth/store/auth.reducer';

const createReducer = asyncReducers =>
  combineReducers({
    auth,
    ...asyncReducers
  });

export default createReducer;

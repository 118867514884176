import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import 'antd/dist/antd.css';
import 'react-sortable-tree/style.css';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import './App.scss';

import history from './history';
import { AppLoader } from './utils/loader/loader';
import { PublicRoutes, PrivateRoutes } from './routes';
import { loadLoggedInUser } from './components/auth/store/auth.action';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = ({ loadLoggedInUser, isAuthenticated, user }) => {
  useEffect(() => {
    loadLoggedInUser();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Helmet defaultTitle={process.env.REACT_APP_META_TITLE}>
        <meta name='description' content='Administrator Dashboard' />
      </Helmet>
      <Router history={history}>
        <Suspense fallback={<AppLoader />}>
          {isAuthenticated && user ? <PrivateRoutes /> : <PublicRoutes />}
        </Suspense>
      </Router>
      <ToastContainer autoClose={4000} />
    </QueryClientProvider>
  );
};

const mapStateToProps = ({ auth: { isAuthenticated, user } }) => ({
  isAuthenticated,
  user,
});
const mapDispatchToProps = { loadLoggedInUser };

export default connect(mapStateToProps, mapDispatchToProps)(App);

import isEmpty from 'lodash/isEmpty';
import { loginConstants } from './auth.type';
import { authUser, invokeApig, signOutUser } from '../../../services/awsLib';

export const loginSuccess = ({ email, name, full }) => async (dispatch) => {
  dispatch({
    type: loginConstants.LOGIN_SUCCESS,
    payload: { email, name },
  });

  localStorage.setItem('user', JSON.stringify(full));
  localStorage.setItem('isUserLoggedIn', true);
};

export const loadLoggedInUser = () => async (dispatch) => {
  const value = await authUser();

  if (value.userObj) {
    sendUserAttributesToGTM(await getUserAttribute(value.userObj));
    dispatch(loadUser());

    return;
  }

  dispatch(logout());
};

const sendUserAttributesToGTM = (attributes) => {
  const userData = {};

  attributes.forEach(({ Name, Value }) => {
    if (!window.dataLayer || !Value) {
      return;
    }

    if (Name === 'sub') {
      userData.userId = Value;
    } else if (Name === 'email') {
      userData.userEmail = Value;
    } else if (Name === 'name') {
      userData.userName = Value;
    } else if (Name === 'phone_number') {
      userData.userPhone = Value;
    } else if (Name === 'custom:tenantId') {
      userData.tenantId = Value;
    }
  });

  if (!isEmpty(userData)) {
    window.dataLayer.push({
      ...userData,
      event: 'userSet',
    });
  }
};

export const getUserAttribute = (currentUser) =>
  new Promise((resolve, reject) =>
    currentUser.getUserAttributes(function (err, result) {
      if (err) {
        return reject(err);
      }

      resolve(result);
    }),
  );

export const loadUser = () => async (dispatch) => {
  try {
    const res = await invokeApig({
      path: '/admin/get-current-user',
      method: 'GET',
    });

    localStorage.setItem('isUserLoggedIn', true);
    localStorage.setItem('user', JSON.stringify(res));
    dispatch({
      type: loginConstants.USER_LOADED,
      payload: res,
    });

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const loadAdminUser = () =>
  invokeApig({
    path: '/admin/get-current-user',
    method: 'GET',
  });

export const forgetPassword = () => () => {};

export const logout = (data) => async (dispatch) => {
  try {
    await signOutUser();

    dispatch({
      type: loginConstants.LOGOUT,
      payload: data,
    });
  } catch (err) {
    console.error(err);
  }
};

import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';

import App from './App';
import store from './store/index';
import './index.css';
import * as serviceWorker from './serviceWorker';

TagManager.initialize({
  gtmId: 'GTM-WFW6V7T',
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();

import { loginConstants } from './auth.type';

const initialState = {
  isAuthenticated: localStorage.getItem('isUserLoggedIn') ? true : false,
  user: localStorage.getItem('user') ? localStorage.getItem('user'): null
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case loginConstants.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: payload
      };
    case loginConstants.USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        user: payload
      };
    case loginConstants.LOGOUT:
      return {
        isAuthenticated: false,
        user: null
      };

    default:
      return state;
  }
}
